<div class="row">
    <div class="col-4">
        {{ subjectName.value }}
    </div>
    <div class="col-4">
        <kip-form-control-tree class="me-2" label="" [control]="treeId" [id]="'tree' + id" [regionId]="regionId"
            [subjectId]="subjectId.value" />
    </div>
    <div class="col-4">
        <kip-form-control-grade class="me-2" label="" [control]="gradeId" [id]="'grade' + id" />
    </div>
</div>