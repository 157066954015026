import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateStruct } from 'moment-extensions-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCacheService } from 'service-lib';
import { Grade, NewEnrolmentExistingStudent, PagedList, PageRequest, Region, SessionScheduleFilter, Student, StudentSettings } from 'ui-common-lib';

import {
  AssessmentFilter,
  AssessmentResultTest,
  GeneralNote,
  Goal,
  InitialLessonPlanResult,
  LessonStatusUpdate,
  NewAssessment,
  NewNote,
  Note,
  ResultSummary,
  SkillbuilderActivity, Strength,
  StudentAssessmentResults,
  StudentAwardList,
  StudentContactDetail,
  StudentDetail,
  StudentEnrolmentDetail,
  StudentFilter,
  StudentGoal,
  StudentLessonHistory,
  StudentPasswordReset,
  StudentRankingDetails,
  StudentStrength,
  StudentSubjectData,
  StudentSummary,
  StudentWeakness,
  Weakness
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends HttpCacheService<number> {

  getRegions(): Observable<Region[]> {
    return this.get<Region[]>('regions');
  }

  getGrades(): Observable<Grade[]> {
    return this.get<Grade[]>('grades');
  }

  queryStudents(page: PageRequest<StudentFilter>): Observable<PagedList<StudentSummary>> {
    return this.post<PagedList<StudentSummary>>('student/query', page)
      .pipe(
        tap(o => {
          for (const s of o.items) {
            this.patchCacheItem(s.id, s);
          }
        })
      );
  }

  updateStudentSettings(studentId: number, studentSettings: StudentSettings): Observable<string> {
    return this.post<string>(`student/${studentId}/settings`, studentSettings);
  }

  getStudentSettingsById(studentId: number): Observable<StudentSettings> {
    return this.get<StudentSettings>(`student/${studentId}/settings`);
  }

  getStudentInfoById(studentId: number): Observable<Student> {
    return this.get<Student>(`student/${studentId}/info`);
  }

  getStudentById(studentId: number): Observable<StudentDetail> {
    return this.get<StudentDetail>(`student/${studentId}`)
      .pipe(
        tap(o => this.patchCacheItem(o.student.id, o.student))
      );
  }

  getStudentRankingById(studentId: number): Observable<StudentRankingDetails> {
    return this.get<StudentRankingDetails>(`student/${studentId}/ranking`);
  }

  queryAwardsByStudentId(studentId: number, requestPage: PageRequest): Observable<StudentAwardList> {
    return this.post<StudentAwardList>(`student/${studentId}/awards/query`, requestPage);
  }

  queryEnrolmentsByStudentId(studentId: number, requestPage: PageRequest<SessionScheduleFilter>): Observable<StudentEnrolmentDetail> {
    return this.post<StudentEnrolmentDetail>(`student/${studentId}/enrolment/query`, requestPage);
  }

  queryAssessmentsByStudentId(studentId: number, requestPage: PageRequest<AssessmentFilter>): Observable<PagedList<ResultSummary>> {
    return this.post<PagedList<ResultSummary>>(`student/${studentId}/assessment-results/query`, requestPage);
  }

  getAssessment(assessmentId: number, lessonId: number | undefined): Observable<NewAssessment> {
    if (assessmentId === 0 && lessonId) {
      return this.get<NewAssessment>(`student/assessments/lessons/${lessonId}`);
    }
    return this.get<NewAssessment>(`student/assessments/${assessmentId}`);
  }

  getAssessmentResults(lessonId: number): Observable<StudentAssessmentResults> {
    return this.get<StudentAssessmentResults>(`student/lessons/${lessonId}/assessment-results`);
  }

  deleteAssessmentResult(assessmentResultId: number): Observable<string> {
    return this.delete<string>(`student/assessments/results/${assessmentResultId}`);
  }

  loadCacheItem(key: number): Observable<StudentSummary> {
    return this.get<StudentSummary>(`student/${key}/summary`);
  }

  postNewEnrolment(enrolment: NewEnrolmentExistingStudent): Observable<string> {
    return this.post<string>(`student/${enrolment.studentId}/enrolment`, enrolment);
  }

  testInitialLessonPlan(studentId: number, tests: AssessmentResultTest[]) {
    return this.post<InitialLessonPlanResult>('tutor/lesson-plan/initial-lesson-from-results',
      { studentId: studentId, assessments: tests });
  }

  getRelatedSkillbuilderActivities(lessonPlanId: number, lessonActivityPlanId: number | undefined) {
    if (lessonActivityPlanId) {
      return this.get<SkillbuilderActivity[]>(`tutor/lesson-plan/skillbuilder-activities/${lessonPlanId}/activity/${lessonActivityPlanId}`);
    }
    return this.get<SkillbuilderActivity[]>(`tutor/lesson-plan/skillbuilder-activities/${lessonPlanId}`);
  }

  postAssessment(newAssessment: NewAssessment): Observable<number> {
    return newAssessment.assessmentId > 0 ? this.put<number>('student/assessment', newAssessment) : this.post<number>('student/assessment', newAssessment);
  }

  getLessonHistoryByStudentId(studentId: number, upTo: DateStruct, numberOfWeeks: number, attendedOnly: boolean, attemptedOnly: boolean): Observable<StudentLessonHistory> {
    return this.get<StudentLessonHistory>(`student/${studentId}/lesson`, {
      ...upTo,
      weeksToRetrieve: numberOfWeeks,
      attendedOnly: attendedOnly,
      attemptedOnly: attemptedOnly
    });
  }

  getContactsByStudentId(studentId: number): Observable<StudentContactDetail[]> {
    return this.get<StudentContactDetail[]>(`student/${studentId}/contact`);
  }

  postNewContact(studentId: number, studentContact: StudentContactDetail): Observable<string> {
    return this.post<string>(`student/${studentId}/contact`, studentContact);
  }

  updateExistingContact(studentId: number, studentContact: StudentContactDetail): Observable<string> {
    return this.put<string>(`student/${studentId}/contact`, studentContact);
  }

  updateExistingStudent(studentId: number, student: Student): Observable<string> {
    return this.put<string>(`student/${studentId}/info`, student);
  }

  getStudentPhoto(studentId: number): Observable<HttpResponse<Blob>> {
    return this.getBlob(`avatar/download-student-photo/${studentId}`);
  }

  clearStudentPhoto(studentId: number): Observable<string> {
    return this.get<string>(`assets/clear-student-photo/${studentId}`, {});
  }

  uploadStudentPhoto(file: File, studentId: number): Observable<string> {
    const formData = new FormData();
    formData.append('studentId', studentId.toString());
    formData.append('file', file);
    return this.post<string>(`assets/upload-student-photo/${studentId}`, formData);
  }

  getNotesByStudentId(studentId: number): Observable<GeneralNote[]> {
    return this.get<GeneralNote[]>(`student/${studentId}/note`);
  }

  postNewNote(studentId: number, note: NewNote): Observable<string> {
    return this.post<string>(`student/${studentId}/note`, note);
  }

  updateExistingNote(studentId: number, note: Note): Observable<string> {
    return this.put<string>(`student/${studentId}/note`, note);
  }

  deleteExistingNote(studentId: number, noteId: number): Observable<string> {
    return this.delete<string>(`student/${studentId}/note/${noteId}`);
  }

  resetPassword(studentId: number, newPassword: StudentPasswordReset): Observable<string> {
    return this.post<string>(`student/${studentId}/password`, newPassword);
  }

  getGoalsByStudentId(studentId: number): Observable<StudentGoal[]> {
    return this.get<StudentGoal[]>(`student/${studentId}/goals`);
  }

  getGoalHistoryByStudentId(studentId: number): Observable<StudentGoal[]> {
    return this.get<StudentGoal[]>(`student/${studentId}/goals/history`);
  }

  setSubjectsByStudentId(studentId: number, studentSubjectData: StudentSubjectData): Observable<string> {
    return this.post<string>(`student/${studentId}/update-subjects`, studentSubjectData);
  }

  getSubjectsByStudentId(studentId: number): Observable<StudentSubjectData> {
    return this.get<StudentSubjectData>(`student/${studentId}/subjects`);
  }

  getStrengthsByStudentId(studentId: number): Observable<StudentStrength[]> {
    return this.get<StudentStrength[]>(`student/${studentId}/strengths`);
  }

  getStrengthHistoryByStudentId(studentId: number): Observable<StudentStrength[]> {
    return this.get<StudentStrength[]>(`student/${studentId}/strengths/history`);
  }

  getWeaknessesByStudentId(studentId: number): Observable<StudentWeakness[]> {
    return this.get<StudentWeakness[]>(`student/${studentId}/weaknesses`);
  }

  getWeaknessHistoryByStudentId(studentId: number): Observable<StudentWeakness[]> {
    return this.get<StudentWeakness[]>(`student/${studentId}/weaknesses/history`);
  }

  postNewGoal(studentId: number, goal: Goal): Observable<string> {
    return this.post<string>(`student/${studentId}/goals`, goal);
  }

  postNewStrength(studentId: number, strength: Strength): Observable<string> {
    return this.post<string>(`student/${studentId}/strengths`, strength);
  }

  postNewWeakness(studentId: number, weakness: Weakness): Observable<string> {
    return this.post<string>(`student/${studentId}/weaknesses`, weakness);
  }

  updateExistingGoal(studentId: number, goal: Goal): Observable<string> {
    return this.put<string>(`student/${studentId}/goals/${goal.id}`, goal);
  }

  updateExistingStrength(studentId: number, strength: Strength): Observable<string> {
    return this.put<string>(`student/${studentId}/strengths/${strength.id}`, strength);
  }

  updateExistingWeakness(studentId: number, weakness: Weakness): Observable<string> {
    return this.put<string>(`student/${studentId}/weaknesses/${weakness.id}`, weakness);
  }

  deleteExistingGoal(studentId: number, goalId: number): Observable<string> {
    return this.delete<string>(`student/${studentId}/goals/${goalId}`);
  }

  deleteExistingStrength(studentId: number, strengthId: number): Observable<string> {
    return this.delete<string>(`student/${studentId}/strengths/${strengthId}`);
  }

  deleteExistingWeakness(studentId: number, weaknessId: number): Observable<string> {
    return this.delete<string>(`student/${studentId}/weaknesses/${weaknessId}`);
  }

  postLessonStatus(studentId: number, lessonStatusUpdate: LessonStatusUpdate): Observable<string> {
    return this.post<string>(`student/${studentId}/lesson/status`, lessonStatusUpdate);
  }

  postConvertToDIY(studentId: number, lessonId: number): Observable<string> {
    return this.post<string>(`student/${studentId}/lesson/convert-to-diy`, { lessonId: lessonId });
  }
}
