@if (label(); as label)
{
<label [class]="labelClass()??''" [for]="name()">{{ label }}
  @if (labelExtra(); as labelExtra)
  {
  <span class="ms-2 small text-uppercase">{{ labelExtra }}</span>
  }
</label>
}
<select class="form-control" [formControl]="gradeId()" [id]="name()">
  @for (grade of grades(); track grade) {
  <option [ngValue]="grade.id">
    {{ grade.title }}
  </option>
  }
</select>