@if (treeId) {
@if (label) {
<label [class]="labelClass??''" [for]="name">{{ label }}@if (description) {
  <span class="ms-2 small text-uppercase">{{ description
    }}</span>
  }</label>
}
<select class="form-control" [formControl]="treeId" [id]="name">
  @for (treeName of treeNames; track treeName) {
  <option [ngValue]="treeName.treeId">
    {{ treeName.regionTag }}
  </option>
  }
</select>
}