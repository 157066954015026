<kip-form-group-wrapper class="p-2" [formGroup]="form" [showCancel]="false" (submittedAndValidated)="save()">
    <h2 class="kip-tab__header">Student Trees</h2>
    <kip-form-control-region id="regionId" label="Student Region" [control]="regionId" />

    <div class="row mb-2">
        <div class="col-4 fw-bold">
            Subject
        </div>
        <div class="col-4 fw-bold">
            Tree
        </div>
        <div class="col-4 fw-bold">
            Grade
        </div>
    </div>
    @for (contact of subjects.controls; track contact; let index = $index) {
    <kip-student-subject [id]="index" [regionId]="regionId" [studentSubjectForm]="$any(contact)" />
    }
</kip-form-group-wrapper>