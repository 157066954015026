import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';

import { StudentSubject } from '../../../../models';

@Component({
    selector: 'kip-student-subject',
    templateUrl: './student-subject.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentSubjectComponent {

    readonly #fb = inject(FormBuilderTypeSafe);

    get treeId() {
        return this.studentSubjectForm.getSafe(x => x.treeId);
    }

    get gradeId() {
        return this.studentSubjectForm.getSafe(x => x.gradeId);
    }

    get subjectId() {
        return this.studentSubjectForm.getSafe(x => x.subjectId);
    }

    get subjectName() {
        return this.studentSubjectForm.getSafe(x => x.subjectName);
    }

    @Input({ required: true }) regionId!: FormControl<number | null>;
    @Input({ required: true }) id = 0;
    @Input({ required: true }) studentSubjectForm: FormGroupTypeSafe<StudentSubject>;

    constructor() {
        this.studentSubjectForm = StudentSubjectComponent.buildDefaultControls(this.#fb);
    }

    /*eslint-disable @typescript-eslint/unbound-method */
    static buildDefaultControls(fb: FormBuilderTypeSafe): FormGroupTypeSafe<StudentSubject> {
        return fb.group<StudentSubject>({
            subjectId: new FormControl<number>(0),
            subjectName: new FormControl<string>('', Validators.required),
            treeId: new FormControl<number | null>(null),
            gradeId: new FormControl<number | null>(null)
        });
    }
    /*eslint-enable @typescript-eslint/unbound-method */

}