export enum StudentDetailTabs {
  LessonHistory = 'lessonHistory',
  Contacts = 'contacts',
  Enrolments = 'enrolments',
  Assessments = 'assessments',
  Notes = 'notes',
  Goals = 'goals',
  Awards = 'awards',
  Settings = 'settings',
  Strengths = 'strengths',
  Weaknesses = 'weaknesses',
  Trees = 'trees'
}
