import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { RegionId } from 'questions-lib';
import { Subscription } from 'rxjs';
import { StudentService, StudentSubjectData } from 'student-common-lib';

import { StudentSubjectComponent } from './student-subject/student-subject.component';

@Component({
    selector: 'kip-student-trees',
    templateUrl: './student-trees.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentTreesComponent implements OnDestroy {

    readonly #changeDetectorRef = inject(ChangeDetectorRef);
    readonly #studentService = inject(StudentService);
    readonly #fb = inject(FormBuilderTypeSafe);

    #studentId: number | undefined;
    #subscriptions: Subscription[] = [];
    #subjectData: StudentSubjectData = { regionId: RegionId.Australia, subjects: [] };

    form!: FormGroupTypeSafe<StudentSubjectData>;

    get subjectData() {
        return this.#subjectData;
    }

    get regionId() {
        return this.form.getSafe(x => x.regionId);
    }

    get subjects() {
        return this.form.getSafeArray(x => x.subjects);
    }

    /* eslint-disable kip/no-unused-public-members */

    @Input({ required: true })
    set studentId(value: number | undefined) {
        if (this.#studentId !== value) {
            this.#studentId = value;
            this.load();
        }
    }

    get studentId() {
        return this.#studentId;
    }

    /* eslint-enable kip/no-unused-public-members */

    constructor() {
        this.#setForm();
    }

    ngOnDestroy() {
        for (const subscription of this.#subscriptions) {
            subscription.unsubscribe();
        }
        this.#subscriptions = [];
    }

    /* eslint-disable kip/no-unused-public-members */

    load() {
        if (this.#studentId) {
            this.#subscriptions.push(
                this.#studentService.getSubjectsByStudentId(this.#studentId).subscribe(subjectData => {
                    this.#subjectData = subjectData;
                    this.#setForm();
                    this.#changeDetectorRef.markForCheck();
                }));
        }
    }

    /* eslint-enable kip/no-unused-public-members */

    save() {
        if (this.#studentId) {
            this.#subscriptions.push(
                this.#studentService.setSubjectsByStudentId(this.#studentId, this.form.value).subscribe(() => {

                    this.#changeDetectorRef.markForCheck();
                }));
        }
    }

    #setForm() {

        /*eslint-disable @typescript-eslint/unbound-method */

        this.form = this.#fb.group<StudentSubjectData>({
            regionId: new FormControl<number | null>(null),
            subjects: new FormArray(this.#subjectData.subjects.map(() => StudentSubjectComponent.buildDefaultControls(this.#fb)))
        });
        this.regionId.disable();
        this.form.patchSafe(this.#subjectData);

        /*eslint-enable @typescript-eslint/unbound-method */
    }
}